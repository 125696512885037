<template>
  <section class="section container has-text-centered" v-on:click.once="mountVideo">
    <div style="position:relative;max-width:1140px; margin: 0 auto">
      <div class="flex-content">
        <div class="">
        <h1>{{lesson.name}}</h1>
      </div>

      <b-button
        v-if="lesson && lesson.extra && lesson.extra.podcast"
        class="m-t-md"
        @click="changeVideo()"
        type="is-primary"
      >{{ podcast? "Ver vídeo aula" : "Ver podcast"}}
      </b-button>
      </div>

      <!-- https://player.vimeo.com/video/168652243 -->
      <!-- src="https://player.vimeo.com/video/272571561" -->
      <!-- :src="lesson.videoUrl" -->
      <iframe
      id="videoItem"
      v-show="!podcast"
      :src="lesson.videoUrl"
        allowfullscreen
        webkitallowfullscreen
        mozallowfullscreen
        style="width:100%;height:640px;"
      ></iframe>

      <iframe
      id="podcastItem"
      v-show="podcast"
      :src="lesson.extra && lesson.extra.podcast ? lesson.extra.podcast : ''"
        allowfullscreen
        webkitallowfullscreen
        mozallowfullscreen
        style="width:100%;height:640px;"
      ></iframe>
    </div>
    <!-- 
    <div class="video" v-if="lesson.videoUrl">
      :videoId="lesson.videoUrl"
      <vimeo-player
        videoId="https://player.vimeo.com/video/272571561"
        class="player"
        ref="player"
        :progress.sync="dados"
        :options="options"
      />@timeupdate="onReady"
    </div>-->
    <b-button
      v-if="!$route.params.status ||$route.params.status === 'false'"
      class="m-t-md"
      @click="exercise"
      type="is-primary"
    >Fazer Exercício</b-button>

    <div v-if="lesson && lesson.extra && lesson.extra.apostila" class="columns">
      <div class="column pdf">
        <a :href="pdf" v-for="pdf in lesson.extra.apostila" :key="pdf">
          <img src="@/assets/img/pdf.png" alt="PDF do curso" />
        </a>
      </div>
    </div>
  </section>
</template>

<script>
import Axios from "axios";
import "../../../assets/js/player";
import { hasProfile, isAuth } from "@/global/auth";
import { setTimeout, setInterval } from "timers";

export default {
  data() {
    return {
      lesson: [],
      options: {
        byline: false,
        title: false
      },
      time: "",
      maxTime: 0,
      dados: "",
      percent: "",
      player: "",
      user: { timeZone: null },
      oldTime: 0,
      watch: "",
      podcast: false,
    };
  },
  beforeRouteLeave(to, from, next) {
    clearInterval(this.watch._id);

    if (isAuth() && this.time && this.time > 0) {
      Axios.put(`/v1/video-progress/${this.oldTime._id}`, {
        time: this.time.toString(),
        completed: this.percent >= 0.5 ? true : false,
        user: this.user._id
      })
        .then(data => {
          if (data.data) {
            next();
          }
        })
        .catch(err => {
          console.error(err);
          next();
        });
    } else {
      next();
    }
  },
  methods: {
    changeVideo() {
      this.podcast = !this.podcast;
      this.player.pause();
      this.playerSecond.pause();

    },
    sendWatchStatus() {
      this.watch = setInterval(() => {
        if (this.time && this.time > 0) {
          Axios.put(`/v1/video-progress/${this.oldTime._id}`, {
            time: this.time.toString(),
            completed: this.percent >= 0.5 ? true : false,
            user: this.user._id
          });
        }
      }, 60 * 1000);
    },
    onReady(data) {
      if (this.time + 0.4 < data.seconds && data.seconds > this.maxTime + 0.2) {
        this.player.setCurrentTime(this.time);
      } else {
        this.time = data.seconds;
        if (data.percent > this.percent) this.percent = data.percent;
        if (data.seconds > this.maxTime) this.maxTime = data.seconds;
      }
    },
    exercise() {
      if (
        (!this.$route.params.skip ||
          this.$route.params.skip !== "true" ||
          !hasProfile("admin")) &&
        (!this.percent || this.percent < 0.5)
      ) {
        this.$buefy.dialog.alert({
          title: "OPPS!",
          message: "Você deve assistir pelo menos <b>50%</b> do vídeo!",
          type: "is-danger",
          hasIcon: true,
          iconPack: "fa"
        });
      } else {
        this.$router.push({
          name: "Prova",
          params: {
            name: this.$route.params.name,
            cur: this.$route.params.cur,
            mod: this.$route.params.mod
          }
        });
      }
    },
    mountVideo() {
      var iframe = document.getElementById("videoItem");
      var iframeSecond = document.getElementById("podcastItem");

      /* eslint-disable */
      this.player = new Vimeo.Player(iframe);
      this.player.on("timeupdate", this.onReady);

      this.playerSecond = new Vimeo.Player(iframeSecond);
      return true;
    },
    getLesson() {
      Axios.get(`/v1/lessons/${this.$route.params.name}`)
        .then(data => {
          if (data.data) {
            this.lesson = data.data;
            this.getTime();
            setTimeout(() => {
              this.mountVideo();
            }, 1);
          }
        })
        .catch(erro => {
          console.error(erro);
        });
    },
    getMe() {
      Axios.get(`/v1/users/me`)
        .then(data => {
          if (data.data) {
            this.user = data.data;
          }
        })
        .catch(erro => {
          console.error(erro);
        });
    },
    getTime() {
      Axios.get(`/v1/video-progress/lesson/${this.lesson._id}`)
        .then(data => {
          if (data.data) {
            this.oldTime = data.data;
            if (data.data.completed) this.percent = 0.6;
            this.maxTime =
              parseFloat(data.data.time) > 0 ? parseFloat(data.data.time) : 0;

            if (parseFloat(this.oldTime.time) > 0) {
              this.$buefy.dialog.confirm({
                message: "Deseja continuar de onde parou?",
                onConfirm: () => {
                  this.time = 0;
                  this.player.setCurrentTime(parseFloat(this.oldTime.time));
                  this.player.play();
                }
              });
            }
          }
        })
        .catch(err => {
          if (
            err.response.data.error[0].message[0] ===
            "videoProgress not found"
          ) {
            let watch = {
              user: this.user._id,
              course: this.$route.params.cur,
              lesson: this.lesson._id,
              time: "0",
              completed: false
            };
            Axios.post("/v1/video-progress", watch)
              .then(data => {
                if (data.data) {
                  this.getTime();
                }
              })
              .catch(err => {
                console.error(err);
              });
          } else {
            console.error(err);
          }
        });
    }
  },
  mounted() {
    this.getLesson();
    this.getMe();
    this.$forceUpdate(this.$route.params.status);
    this.sendWatchStatus();
  }
};
</script>

<style lang="scss" scoped>
h1 {
  font-size: 1.2rem;
  margin-bottom: 1rem;
}

.flex-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}

.pdf {
    display: flex;
    justify-content: flex-end;

    margin-right: 50px;
    margin-top: 50px;

    a {
      width: 100px;
      height: 100px;
    }
  }

.alert-modal-container {
  position: fixed;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  background-color: rgba($color: #000000, $alpha: .6);
  z-index: 9999999;
  
  form {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 9999999;
    background-color: #fff;
    padding: 20px;
    max-width: 30%;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  p {
    margin-bottom: 1rem;
  }

  button {
    margin-top: 1rem;
  }
}
</style>

